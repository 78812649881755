import React, { FunctionComponent } from "react"
import {
  BreadcrumbResource,
  Breadcrumbs
} from "../../../../components/breadcrumbs/breadcrumbs"
import { TopicDetails } from "../../topic-types"
import styles from "./topic-header.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface TopicHeaderProps extends TopicDetails {
  breadcrumbs: BreadcrumbResource[]
}

export const TopicHeader: FunctionComponent<TopicHeaderProps> = ({
  breadcrumbs,
  name,
  description
}) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <Breadcrumbs items={breadcrumbs} size="sm" />
      <h1 className={styles.name}>{name}</h1>
      <Markdown content={description} className={styles.description} />
    </div>
  </header>
)
