import { TopicDetailPageQuery } from "../../../../graphql/types"
import { TopicDetails } from "../topic-types"
import { PathPrefix } from "../../../core/constants"
import { truncate } from "../../../core/utils"
import { mapMetadata } from "../../../core/mappers/map-metadata"

export const mapSourceTopicDetailsToView = (
  query: TopicDetailPageQuery
): TopicDetails => {
  const topic = query.contentfulTopic!
  const intlDescription = query.contentfulIntl?.values?.description
  const topicDescription = topic.description?.childMarkdownRemark?.plainText
  const fallbackDescription =
    intlDescription && topic.name
      ? intlDescription?.replace("[topic]", topic.name)
      : null
  const metadata = mapMetadata(
    topic.metadata,
    {
      title: topic.name,
      description: topicDescription
        ? truncate(topicDescription)
        : fallbackDescription
    },
    {
      title: topic.pageTitle
    }
  )

  return {
    id: topic.id!,
    name: topic.name!,
    slug: `${PathPrefix.Topics}/${topic.slug!}`,
    topicCode: topic.topicCode!,
    description: topic.description?.childMarkdownRemark?.rawMarkdownBody || "",
    metadata
  }
}
