import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { TopicDetailPageQuery } from "../../../../graphql/types"
import { PageProps, PageContext } from "../../../types/page"
import { mapSourceTopicBreadcrumbsToView } from "../mappers/topic-breadcrumbs-mapper"
import { mapSourceTopicDetailsToView } from "../mappers/topic-details-mapper"
import { TopicDetailsPage } from "../components/topic-details-page/topic-details-page"
import { Page } from "../../../components/page/page"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { defaultMetadata } from "@core/constants"

export interface TopicPageContext extends PageContext {
  topicCodes: string[]
}

export interface TopicPageProps<Query> extends PageProps<Query> {
  pageContext: TopicPageContext
}

export const TopicTemplate: FunctionComponent<
  TopicPageProps<TopicDetailPageQuery>
> = ({ data, ...props }) => {
  const breadcrumbs = mapSourceTopicBreadcrumbsToView(data!.allContentfulTopic)
  const details = mapSourceTopicDetailsToView(data!)
  const topicData = data?.contentfulTopic
  const { metadata } = topicData || {}

  const metadataOverrides = {
    title: metadata?.title || details.metadata?.title || defaultMetadata.title,
    description:
      metadata?.description ||
      details.metadata?.description ||
      defaultMetadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src ||
      details.metadata?.openGraphImage ||
      defaultMetadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...details.metadata, ...metadataOverrides }}
      pageOptions={{
        defaultSearchIndex: "newest",
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Topics,
          {
            label: details.name,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <TopicDetailsPage breadcrumbs={breadcrumbs} {...details} />
    </Page>
  )
}

export default TopicTemplate

export const query = graphql`
  query TopicDetailPage($id: String, $topicCodes: [String]) {
    contentfulTopic(id: { eq: $id }) {
      ...TopicDetailsWithResources
    }
    contentfulIntl(key: { eq: "topic" }) {
      values {
        description
      }
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      nodes {
        name
        slug
      }
    }
  }
`
