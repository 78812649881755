import { BreadcrumbResource } from "../../../components/breadcrumbs/breadcrumbs"
import { TopicDetailsListFragment } from "../../../../graphql/types"
import { PathPrefix } from "../../../core/constants"

export const mapSourceTopicBreadcrumbsToView = (
  query: TopicDetailsListFragment
): BreadcrumbResource[] =>
  query.nodes.map((node) => ({
    label: node.name!,
    link: `${PathPrefix.Topics}/${node.slug!}`
  }))
