import React, { FunctionComponent } from "react"
import { BreadcrumbResource } from "../../../../components/breadcrumbs/breadcrumbs"
import { TopicDetails } from "../../topic-types"
import { TopicHeader } from "../topic-header/topic-header"
import { SearchFilters, SearchConfig } from "../../../search/search-types"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { defaultSearchIndexes } from "../../../../features/search/search-constants"
import styles from "./topic-details-page.module.scss"
import { useTopicLists } from "@hooks/use-topic-code-data"

export interface TopicDetailsProps extends TopicDetails {
  breadcrumbs: BreadcrumbResource[]
}

const indexes = defaultSearchIndexes.filter((x) => x.id !== "featured")

const filters: SearchFilters = [
  {
    title: "search-filter-type",
    attribute: "type"
  },
  {
    title: "search-filter-topics",
    attribute: "fields.primaryTopic.fields.name"
  },
  {
    title: "search-filter-teachers",
    attribute: "fields.teachers.fields.shortName"
  }
]

export const TopicDetailsPage: FunctionComponent<TopicDetailsProps> = (
  props
) => {
  const parentTopic = props.name
  const subTopics = useTopicLists(props.name)
  const filterQueries = subTopics.reduce<string>(
    (acc, curr) => `${acc} OR fields.primaryTopic.fields.name:"${curr}"`,
    `fields.primaryTopic.fields.name:"${parentTopic}"`
  )

  const config: SearchConfig = {
    filters: filterQueries
  }

  return (
    <div className={styles.topicPage}>
      <TopicHeader {...props} />
      <SearchResults
        indexes={indexes}
        config={config}
        filters={filters}
        variant="list"
      />
    </div>
  )
}
